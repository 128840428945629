<template>
   <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Simulador</h4>
          <h6 class="mt-3 mb-1">Contrato 4163457XXXXX</h6>
          <p class="text-info mb-1">Claro SA</p>
          <p class="mb-1">Total devido:</p>
          <h5 class="text-danger">R$ 1.900,00</h5>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row" style="">
        <!-- aqui-->
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-6">
          <div class="card">
            <div class="card-header" style="">
              <div class="row">
                <div class="col-12">
                  <h5>Pagamento à vista <span class="badge badge-pill badge-success" style="font-size:10px;">30% de desconto</span></h5>
                </div>
              </div>
            </div>
            <div class="card-body pb-2">
              <div class="row">
                <div class="col-lg-12 pb-2 col-6">
                  <h6 class="mb-1">Valor com desconto:</h6>
                  <h5 class="text-primary">R$ 1340,00</h5>
                </div>
                <div class="col-lg-12 small col-6">
                  <h6 class="mb-1">Data do vencimento:</h6>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline1" name="customRadioInlineA" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline1">05/12/2022</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline2" name="customRadioInlineA" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline2">15/12/2022</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline3" name="customRadioInlineA" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline3">25/12/2022</label>
                  </div>
                </div>
                <div class="text-danger col-12 text-center col-lg-12">
                  <router-link to="/dashboard/meusBoletos" class="nav-item"> 
                  <a class="btn btn-primary mt-3 text-white">Quero pagar à vista </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-6">
          <div class="card">
            <div class="card-header" style="">
              <div class="row">
                <div class="col-12">
                  <h5>Pagamento parcelado&nbsp;&nbsp;<span class="badge badge-pill badge-success" style="font-size:10px;">até 22% de desconto</span></h5>
                </div>
              </div>
            </div>
            <div class="card-body pb-2">
              <div class="row">
                <div class="mb-3 col-12 col-lg-12">
                  <label>Valor de entrada:</label>
                  <div class="form-group">
                    <input type="text" class="form-control form-control-sm" placeholder="Insira o valor em R$ da entrada" name="Valor de entrada">
                    <p class="text-info">Caso não deseje dar entrada deixe o campo sem preencher.</p>
                  </div>
                </div>
                <div class="col-12 pb-3 col-lg-12">
                  <label for="inputState">Tipo de pagamento da entrada:</label>
                  <select id="inputState" class="form-control form-control-sm">
                    <option selected="" value="Boleto">Boleto</option>
                    <option value="Pix">Pix</option>
                  </select>
                </div>
                <div class="col-12 pb-3 col-lg-12">
                  <h6 class="mb-1">Dia do vencimento:</h6>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline10" name="customRadioInlineD" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline10">05</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline11" name="customRadioInlineD" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline11">15</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline mx-3">
                    <input type="radio" id="customRadioInline12" name="customRadioInlineD" class="custom-control-input">
                    <label class="custom-control-label" for="customRadioInline12">25</label>
                  </div>
                </div>
                <div class="col-12 text-center col-lg-12">
                  <!-- <router-link to="/dashboard/propostas" class="nav-item">  -->
                    <button class="btn btn-primary mt-3 text-white" @click="propostas">Ver propostas</button>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal fade mt-5" id="logout">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title text-primary">Logout</h6> <button type="button" class="close" data-dismiss="modal"> <span>×</span> </button>
        </div>
        <div class="modal-body">
          <h6>Deseja encerrar a sessão?</h6>
        </div>
        <div class="modal-footer"> <a href="/" class="btn btn-dark px-5">Sair</a> <button type="button" class="btn btn-outline-dark" data-dismiss="modal">Voltar</button> </div>
      </div>
    </div>
  </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
// import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
export default {
    name: "negociarDivida",
    components: {
      // ProgressLoading,
      //ContentMenu
    },
    data () {
        return {
            loadOffers: false,
            negociacao: null,
            selectDeal:null,
            selectedAll: [],
            digitalLine:null,
            checkSelectAll: false,
            checkContract: false,
            validationError:false,
            valueByContract: {},
            links: [],
            selected: {},
            dataAtual : new Date(Date.now()),
            offers: [],
            contracts:[],
            checkedParcels: [],
            checkedParcelsByContract: {},
            selectVariable: true,
            valueTotal: 0,
            
        }
    },
    methods: {
        formatValue,
        formatData,
        ...mapActions("meusContratos", ["ActionGetOffersClaro"]),
        
        async propostas() {
          // localStorage.setItem("contracts", JSON.stringify(value));
          const contracts = Array.isArray(this.contracts) ? this.contracts : [this.contracts];
          this.$router.push({
            name: "propostas",
            params: { contractsArray: contracts, contracts: this.contracts },
          });
        },


        async selectAllByContract(contract) {

          contract.parcelas.map((installment) => {
            
            if (!this.selected[contract.contrato] && this.checkedParcels.includes(installment)) return
            else if (this.selected[contract.contrato] && !this.checkedParcels.includes(installment)) return
            else if (!this.selected[contract.contrato] && !this.checkedParcels.includes(installment)) {
              this.checkedParcels.push(installment)
            } else this.checkedParcels.splice(this.checkedParcels.indexOf(installment), 1)
            this.calculateSingleInstallment(contract.contrato, installment)
          })
          this.selected[contract.contrato] = !this.selected[contract.contrato] 
        },

        calculateSingleInstallment(contract, parcel) {
          this.valueTotal = 0
          
          if (this.checkedParcels.length === 0) {
            this.valueByContract[contract] = 0
            this.checkedParcelsByContract[contract] = []
            return
          }
          if (this.checkedParcels.filter((value) => { return value === parcel }).length === 0) {
            this.checkedParcelsByContract[contract].splice(this.checkedParcelsByContract[contract].indexOf(parcel), 1)
            this.valueByContract[contract] -= parseFloat(parcel.valor.replace(".", "").replace(",", "."), 10)
          } else {
            this.checkedParcelsByContract[contract].push(parcel)
            this.valueByContract[contract] += parseFloat(parcel.valor.replace(".", "").replace(",", "."), 10)
          }

          this.checkedParcels.map((value) => {
            this.valueTotal += parseFloat(value.valor.replace(".", "").replace(",", "."), 10)
          })
        },

        selectAll() {
          this.clear()

          this.contracts.map((contract) => {
            this.selectedAll.push(contract.contrato)
            this.selected[contract.contrato] = true
            contract.parcelas.map((installment) => {
              this.checkedParcelsByContract[contract.contrato].push(installment)
              this.checkedParcels.push(installment)
              this.valueTotal += parseFloat(installment.valor.replace(".", "").replace(",", "."), 10)
              this.valueByContract[contract.contrato] += parseFloat(installment.valor.replace(".", "").replace(",", "."), 10)
            })
          })
        },

        clear() {
          this.selectedAll = []
          this.checkedParcels = []
          this.valueTotal = 0
          this.contracts.map((contract) => {
            this.valueByContract[contract.contrato] = 0
            this.checkedParcelsByContract[contract.contrato] = []
          })
        },

        voltar() {
             this.$router.push({ 
              name: 'dashboard',
              params: { contracts: this.contracts }
            });
        },

        checkInstallmentRules() {
          var result = true;

          Object.keys(this.checkedParcelsByContract).map((value) => {
              this.checkedParcelsByContract[value].sort(function(a, b) {
                return parseInt(a.parcela) - parseInt(b.parcela);

              });
              this.contracts.find((item) => {
                return item.contrato === value;
                
              }).parcelas.slice(0, this.checkedParcelsByContract[value].length).map((item2, index) => {
                if(item2.parcela !== this.checkedParcelsByContract[value][index].parcela) {
                  result = false;
                }
              });
          });

          return result;
          
        },

        async negociarAVista() {

          if (await !this.checkInstallmentRules()) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                blockClass: 'custom-block-class',
                title: "Ocorreu um erro",
                message: `Selecione parcela(s) em sequência!`,
              });
              return;
          }

          let today = new Date();
          let qtdParcelsPending = 0;
          let date = new Date();
          // let parts = date.split('/') 


          this.checkedParcels.map((value) => {
            date = formatData(value.vencimento, 'YYYY-MM-DD', 'L');
            let parts = date.split('/') 
              // value.vencimento = new Date(parts[2], parts[1] - 1, parts[0])
            date = new Date(parts[2], parts[1] - 1, parts[0])
            
            if(today < date) {
              qtdParcelsPending++;
            }
              // console.log(today + '  ' + formatData(value.vencimento, 'YYYY-MM-DD', 'L'));
              // console.log(today > value.vencimento);
              // console.log(today < value.vencimento);
              // console.log(today > date);
              // console.log(today < date);
              
          });

              // console.log('qtdParcelsPending ' + qtdParcelsPending);


          if(qtdParcelsPending > 0) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                blockClass: 'custom-block-class',
                title: "Ocorreu um erro",
                message: `Selecione apenas parcela(s) vencida(s)!`,
              });
              return;
          }

          if(this.checkedParcels.length < 1) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                blockClass: 'custom-block-class',
                title: "Ocorreu um erro",
                message: `Selecione pelo menos 1 parcela para negociar!`,
              });
              return;
          }

          
          
          for (const key in this.checkedParcelsByContract) {
            this.checkedParcelsByContract[key].length > 0 ? 
            this.checkedParcelsByContract[key].total = this.valueByContract[key] :
            delete this.checkedParcelsByContract[key]
          }
          localStorage.setItem('contractsctsToUpdate', JSON.stringify(this.checkedParcelsByContract))
          this.$router.push({
              name: "negociar",
              params: { contracts: this.checkedParcelsByContract },
          });
        }

    },
    async created() {
        // this.user.Menus.forEach((menuId) => {
        //   switch (parseInt(menuId)) {
        //     case 2:
        //       this.links.push({
        //         icon: "",
        //         text: "Contratos Negociáveis",
        //         route: "/dashboard/minhas-dividas",
        //       });
        //       break;
        //     case 1:
        //       this.links.push({
        //         icon: "",
        //         text: "Boletos Ativos",
        //         route: "meusBoletos",
        //       });
        //       break;
        //     case 3:
        //       this.links.push({
        //         icon: "",
        //         text: "Acordos",
        //         route: "/dashboard/meus-acordos",
        //       });
        //       break;
        //   }
        // });

        this.loadOffers = true;

        // let contractsFromLocalStorage = JSON.parse(localStorage.getItem("contracts"))
        // this.contracts = Array.isArray(contractsFromLocalStorage) && contractsFromLocalStorage !== null ? contractsFromLocalStorage : this.voltar();
        this.contracts = this.$route.params.contracts;
        // console.log('USER: ' + JSON.stringify(this.user))


        


        // this.contracts.map((contract) => {
        //   this.selected[contract.contrato] = false
        //   this.valueByContract[contract.contrato] = 0
        //   this.checkedParcelsByContract[contract.contrato] = []
        // })

        this.loadOffers = false;
    },
    computed: {
      ...mapState("auth", ["user"]),
      // ...mapState("meusContratos", ["ActionGetOffersClaro"]),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>
<style scoped>
/* 
a:hover {
  background-color: #31a07f;
} */




</style>
